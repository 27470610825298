const studentAzurePath = '/student-support-azure';
const enrollmentAzurePath ='/enrollmentcopilot';
const chatbotPath = "/chatbot";

// $(document).ready(function() {
//     const currentUrl = window.location.pathname;
//     if(currentUrl == studentAzurePath){
//         getChatbotIdForStudent();
//     }else if(currentUrl == enrollmentAzurePath){
//         getChatbotIdForEnrollmentBot();
//     }
// });

$(document).ready(function() {
	const currentUrl = window.location.pathname;
    if(currentUrl == studentAzurePath){
        checkSASCookiesForStudentAzureChatbot();
    }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
        checkSASCookiesForEnrollmentChatbot();
    }
});

// Function to generate a UUID
function generateUUID() {
	const uuid = generateRandomUUID();
	return uuid;
}

// Generate a random UUID
function generateRandomUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		const r = Math.random() * 16 | 0;
		const v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

// Check if it's the first time the page has loaded
if (!localStorage.getItem('session_uuid')) {
	localStorage.setItem('session_uuid', generateUUID());
}

function sendMessage() {
    const messageInputField = document.getElementById("input_msg_chat_popup");
    const message = messageInputField.value.trim();
    if (message !== "") {
        $('<li class="media sent"><div class="media-body"><div class="msg-box"><div style="box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;"><p>' + message + '</p></div></div></div></div></div></li>').appendTo(".chatapp-single-chat  ul.list-unstyled");
        messageInputField.value = "";
    }
    return message
    }

    $(document).on("click", ".start-conversation", function (e) {
        $('.init-content-wrap').addClass('d-none');
        $('.list-unstyled,footer > .input-group').removeClass('d-none');
        $('.chatbot-popup-body .nicescroll-bar').css('margin-top', 0);
        $('.chatbot-popup-body').css('margin-top', 0);
        $('.hk-chatbot-popup header').css('padding-bottom', '.5rem');
        $('.hk-chatbot-popup footer .chatbot-intro-text').addClass('d-none');
        return false;
    });

    $(document).on("click", "#starting_ques_1", function (e) {
        var starting_ques_1 = document.getElementById("starting_ques_1");
        var id = "#input_msg_chat_popup";
        e.preventDefault();
        const messageInputField = document.getElementById("input_msg_chat_popup");
        messageInputField.value = starting_ques_1.innerText;
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            inputMsgChatPopupStudentAzureChatbot({which : 13}, id);
        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            inputMsgChatPopupEnrollmentChatbot({which : 13}, id);
        }
    });
    
    $(document).on("click", "#starting_ques_2", function (e) {
        var starting_ques_2 = document.getElementById("starting_ques_2");
        var id = "#input_msg_chat_popup";
        e.preventDefault();
        const messageInputField = document.getElementById("input_msg_chat_popup");
        messageInputField.value = starting_ques_2.innerText;
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            inputMsgChatPopupStudentAzureChatbot({which : 13}, id);

        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            inputMsgChatPopupEnrollmentChatbot({which : 13}, id);
        }
    });
    
    $(document).on("click", "#starting_ques_3", function (e) {
        var starting_ques_3 = document.getElementById("starting_ques_3");
        var id = "#input_msg_chat_popup";
        e.preventDefault();
        const messageInputField = document.getElementById("input_msg_chat_popup");
        messageInputField.value = starting_ques_3.innerText;
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            inputMsgChatPopupStudentAzureChatbot({which : 13}, id);

        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            inputMsgChatPopupEnrollmentChatbot({which : 13}, id);
        }
    });

    $(document).on("click", "#send_query", function (e) {
        $('.chat_FAQs').addClass('d-none');
        $('.dashboard').removeClass('d-none');
        var id = "#input_msg_chat_popup";
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            inputMsgChatPopupStudentAzureChatbot({which : 13}, id);

        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            inputMsgChatPopupEnrollmentChatbot({which : 13}, id);
        }
    });

    if (!localStorage.getItem('sas_token') || localStorage.getItem('sas_token')=='null') {

        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            SasTokenForStudentAzureChatbot();
        }else if(currentUrl == enrollmentAzurePath){
            SasTokenForEnrollmentChatbot();
        }
    }

    //To show history and FAQs Tab
    $(document).on("click","#history_chat",function(e){
        $('#chat_FAQs').animate({scrollTop:0},'fast');
        $('#historyTab').animate({scrollTop:0},'fast');
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            // refreshChatStudentAzureChatbot();
            loadHistoryForStudent();
            $('.chatbot-popup-body').addClass('history-popup-body');
            $('.nicescroll-bar').addClass('d-none');
            $('.chat-history').removeClass('d-none');
            $('.list-unstyled,footer > .input-group').removeClass('d-none');
            activeHistoryTab();
        }else if(currentUrl == enrollmentAzurePath){
            loadHistoryForEnrollment();
            $('.chatbot-popup-body').addClass('history-popup-body');
            $('.nicescroll-bar').addClass('d-none');
            $('.chat-history').removeClass('d-none');
            $('.list-unstyled,footer > .input-group').removeClass('d-none');
            activeHistoryTab();
        } else if (currentUrl == chatbotPath) {
            loadHistoryForEnrollment();
        }

    })

    $(document).on("click","#refresh_chat",function (e) {
        $('.chatbot-popup-body').removeClass('history-popup-body');
        $('.nicescroll-bar').removeClass('d-none');
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            refreshChatStudentAzureChatbot(e, "#refresh_chat");
            $('.list-unstyled,footer > .input-group').removeClass('d-none');
        }else if(currentUrl == enrollmentAzurePath){
            refreshChatEnrollmentChatbot(e, "#refresh_chat");
            $('.list-unstyled,footer > .input-group').removeClass('d-none');
        } else if(currentUrl == chatbotPath) {
            refreshChatEnrollmentChatbot(e, "#refresh_chat");
            
        }
    }); 

    $(document).on("keypress","#input_msg_chat_popup", function (e) {
        $('.chat-history').addClass('d-none');
        $('.chatbot-popup-body').removeClass('history-popup-body');
        $('.nicescroll-bar').removeClass('d-none');
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            inputMsgChatPopupStudentAzureChatbot(e,"#input_msg_chat_popup");

        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            inputMsgChatPopupEnrollmentChatbot(e, "#input_msg_chat_popup");
        }
    });

    $(document).on('click', "#input_msg_chat_popup", function (e) {
        const currentUrl = window.location.pathname;
        if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            changeSendButtonColor();
        } else if (currentUrl == studentAzurePath) {
            changeSendButtonColorForStd();
        }
    })

    //history queries trigger on user click
    $(document).on("click", ".history-query", function(e) {
        $('.chat-history').addClass('d-none');
        $('.chatbot-popup-body').removeClass('history-popup-body');
        $('.nicescroll-bar').removeClass('d-none');
        var queryText = $(e.target).text();
        e.preventDefault();
        historyAndFaqQueries(queryText);
    });

    //FAQs queries trigger on user click
    $(document).on("click", ".faqs_Queries", function(e) {
        $('.chat-history').addClass('d-none');
        $('.chatbot-popup-body').removeClass('history-popup-body');
        $('.nicescroll-bar').removeClass('d-none');
        var queryText = $(this).find('a').text();
        e.preventDefault();
        historyAndFaqQueries(queryText);
    });

    // Common method to trigger query when user click on history or FAQs queries
    function historyAndFaqQueries(query){
        $('.list-unstyled,footer > .input-group').removeClass('d-none');
        var id = "#input_msg_chat_popup";
        const messageInputField = document.getElementById("input_msg_chat_popup");
        messageInputField.value = query;
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            inputMsgChatPopupStudentAzureChatbot({which : 13}, id);
        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            inputMsgChatPopupEnrollmentChatbot({which : 13}, id);
        }
    }

    $(document).on("click", "#like_convo", function (e) {
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            likeConvoStudentAzureChatbot(e, this);
        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            likeConvoEnrollmentChatbot(e, this);
        }
    });

    $(document).on("click", "#dislike_convo", function (e)  {
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            dislikeConvoStudentAzureChatbot(e, this);
        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            dislikeConvoEnrollmentChatbot(e, this);
        }
    });

    $(document).on("click", "#send_dislike_feedback", function (e)  {
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            sendDislikeFeedbackStudentAzureChatbot(e, this);
        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            sendDislikeFeedbackEnrollmentChatbot(e, this);
        }
    });

    $(document).on("click", "#send_like_feedback", function (e)  {
        const currentUrl = window.location.pathname;
        if(currentUrl == studentAzurePath){
            sendLikeFeedbackStudentAzureChatbot(e, this);
        }else if(currentUrl == enrollmentAzurePath || currentUrl == chatbotPath){
            sendLikeFeedbackEnrollmentChatbot(e, this);
        }
    });